@font-face {
  font-family: "FreightSans Pro";
  src: url("./freight-sans-light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "FreightSans Pro";
}
